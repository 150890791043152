.card-summary {
width: 90%;
border-radius: 10px;
border: 1px solid #4E5E90;
padding: 10px;
margin-top: 20px;
background-color: #DEDFF2;
margin-left: 40px;
margin-right: 40px;
    
}

.graph-card {
width: 40%;
border-radius: 10px;
border: 1px solid #4E5E90;
padding: 10px;
margin-top: 20px;
margin-left: 40px;     
}

.graph-card1 {
  width: 40%;
  border-radius: 10px;
  border: 1px solid #4E5E90;
  padding: 10px;
  margin-top: 20px;
  margin-left: 134px;     
  }

.image3 {
  width:20px;
  height:30px;
  /* margin-top:90;
  margin-left:10;
  margin-right:10; */
  
}

.arrow {
  width:14px;
  height:auto;
  margin-left:5px;

}

/* .locationheader-tenantsummary {
padding-bottom: 10px;
margin-top: 30px;
} */

.locationheadercolor-tenantsummary {
background-color: #2faa4f;
color: white;
font-size: 16px;
font-weight: bold;
padding: 9px;
text-transform: uppercase;
}

.table-income {
font-size:14px;
border-collapse: collapse;
margin-top:7px;
width: 100%;
/* margin-left: 40px;
margin-right: 40px; */
}
  .table-income td {
  text-align: left;
  padding: 6px;
  margin-right: 40px;
  }
  
  .table-income th {
  text-align: left;
  padding: 6px;
  
  }
  
.table-income-thead{
background-color: #1e459c;
color: white;
font-weight: normal;
}
.table-income tbody tr:nth-child(even) {
  /* background-color:#ebf5f7; */
  background-color:white;
  /* border-bottom: 1px solid #dbdbdb; */
  }
  .table-income tbody tr:nth-child(odd) {
      background-color:#ebeffa;
  /* background-color:white; */
  /* border-bottom: 1px solid #dbdbdb; */
  }
.section-content-title-tenantsummary {
            
  font-size: 20px;
  font-weight: bold;
  color: #2fab4f;
  text-align: left;
  padding: 11px;
  margin-top: 33px;
  border-bottom: 2px solid #53c270;
  background-color: #e6faeb;
  text-transform: uppercase;
  margin-left: 35px;
  margin-right: 35px;
}
.table-container-tenant-summary {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c7c7c7;
  padding: 10px;
  text-align: left;
      
  }
.locationheader-tenantsummary {
padding-bottom: 10px;
margin-top: 30px;
text-align: left;
/* margin-left: 40px; */
margin-top: 25px;
/* margin-right: 40px; */
}

.locationheadercolor-tenantsummary {
background-color: #2faa4f;
color: white;
font-size: 16px;
font-weight: bold;
padding: 9px;
text-transform: uppercase;
}

.arrow2 {
  width:50px;
  height:50px;
  margin-top:120px;
  margin-left:50px;
}

.square-bullet {
  width:20px;
  height:20px;
  margin-top: 5px;
  margin-left:10px;
}
.monthly-arrow {
  width:20px;
  height:20px;
  margin-top:3px;
  margin-left:10px;
}

  